import React, {useCallback, useEffect, useRef} from 'react';
import styles from './Input.module.scss';
import { ReactComponent as CLoseSVG } from '../../close.svg';

export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export interface InputOwnProps {
  label?: string;
  value?: string;
  error?: boolean;
  onChange: (value: string) => void,
}

export type InputProps = Overwrite<
  Partial<React.InputHTMLAttributes<HTMLInputElement>>,
  InputOwnProps
  >

export function Input(props: InputProps): React.ReactElement<InputProps> {
  const { children, label, type = 'text', value, error, onChange, ...rest } = props;

  const handleChange = useCallback(({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  }, [onChange]);

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <div className={styles.Container}>
      <input
        className={`${styles.Input} ${error && styles.InputError} ${value && styles.InputValue}`}
        type={type}
        value={value}
        onInput={handleChange}
        {...rest} />
      <label className={`${styles.Label} ${value && styles.LabelActive}`}>
        {label}
      </label>
      {value && <CLoseSVG className={styles.CLose} onClick={handleClear} />}
    </div>
  );
}
