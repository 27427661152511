import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from './Auth.module.scss';
import {Button} from "../Button";
import {Notification} from "../Notification";
import {baseFetch, LoadingState} from "../../utils";
import {useLocation} from "react-router";
import {Input} from "../Input";
import { ReactComponent as LogosSVG } from '../../logos.svg';
import {AuthResponse, ResponseApiService} from "../../models/apiFitch.model";
import {getConfig} from "../../utils/configGet";

export interface IUrlParams {
  redirect_uri: string;
}

export const Auth: React.FC = () => {
  const { search } = useLocation();
  const { kaluga, mo, tatar, service, region05, region105, region72, acl_subsystem, acl_url } = getConfig();

  const parseSearch = useMemo(() => {
    const params = new URLSearchParams(search);
    return Object.fromEntries(params) as unknown as IUrlParams;
  }, [search]);

  const [loginStatus, setLoginStatus] = useState<LoadingState>(LoadingState.NOT_ASKED);
  const [loginStatusErrorCode, setLoginStatusErrorCode] = useState<string>('');
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSetLogin = useCallback((value: string) => {
    setLogin(value);
  }, [setLogin]);

  const handleSetPassword = useCallback((value: string) => {
    setPassword(value);
  }, [setPassword]);

  const disabledSubmit = useMemo(() => (!login.length || !password.length), [login, password]);

  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [notificationText, setNotificationText] = useState<string>('');

  const handleCLoseNotification = useCallback(() => {
    setNotificationOpen(false);
  }, [setNotificationOpen]);

  /**
   * Определение урла для возврата по истории браузера на основе aupd_source
   */
  const getUrlBySource = useCallback(() => {

    const SOURCE = localStorage.getItem('aupd_source') || '';

    switch (SOURCE) {
      case '05': { return region05; }
      case '16': { return tatar; }
      case '40': { return kaluga; }
      case '50': { return mo; }
      case '72': { return region72; }
      case '105': { return region105; }
      case '116': { return tatar; }
      default: { return `https://${service}`; }
    }
  }, [mo, kaluga, tatar, region05, service, region105, region72]);

  const handleBack = useCallback(() => {
    const url = getUrlBySource();
    if (url?.length) {
      document.location.href = url;
    } else {
      window.history.go(-2)
    }
  }, [getUrlBySource]);

  useEffect(() => {
    if (loginStatus === LoadingState.FAILED) {
      setNotificationOpen(true);
    }
  }, [loginStatus]);

  const handleRedirect = useCallback((code: string | null) => {
    if (code?.length) {
      const url = parseSearch?.redirect_uri?.length
        ? parseSearch?.redirect_uri
        : `https://${service}/v3/auth/kauth/callback`;

      document.location.href = `${url}${url?.includes('?') ? '&' : '?'}code=${code}`;
    } else {
      setNotificationText('Упс! Что-то пошло не так');
    }

  }, [parseSearch, setNotificationText, service]);


  const handleLogin = useCallback((event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!disabledSubmit) {

      setLoginStatus(LoadingState.LOADING);
      setNotificationOpen(false);
      setNotificationText('');
      setLoginStatusErrorCode('');

      baseFetch(acl_url, {
        method: 'POST',
        body: {
          login: login,
          password_plain: password,
        },
        headers: {
          'x-mes-subsystem': acl_subsystem
        }
      }).then((value) => {
        const { data } = value as ResponseApiService<AuthResponse>;

        setLoginStatus(LoadingState.LOADED);
        setLoginStatusErrorCode('');

        handleRedirect(data?.authentication_token || null);
      }).catch((error) => {
        setLoginStatus(LoadingState.FAILED);
        setNotificationText(error?.description || 'Упс! Что-то пошло не так');
        setLoginStatusErrorCode(error?.status || '');
      })
    }
  }, [
    login,
    password,
    setLoginStatus,
    disabledSubmit,
    setNotificationText,
    setLoginStatusErrorCode,
    handleRedirect,
    acl_subsystem,
    acl_url
  ]);

  return (
    <div className={styles.Auth}>

      <div className={styles.Box}>

        {notificationOpen && <Notification onClose={handleCLoseNotification} text={notificationText}/>}

        <div className={styles.Wrapper}>

          <div className={styles.Back}>
            <Button isLink onClick={handleBack} type="button">Назад<span className={styles.HideMobile}> на главную страницу</span></Button>
          </div>

          <LogosSVG className={styles.Logo} />

          <form className={styles.Form} onSubmit={handleLogin} id="formLogin">
            <div className={styles.Title}>Вход по логину</div>
            <div className={styles.Text}>Вход по логину и паролю, предоставленным в школе</div>

            <div className={styles.Fields}>
              <div className={styles.Field}>
                <Input label="Логин" value={login} onChange={handleSetLogin} error={(loginStatusErrorCode === '401')} />
              </div>
              <div className={styles.Field}>
                <Input type="password" label="Пароль" value={password} onChange={handleSetPassword} error={(loginStatusErrorCode === '401')} />
              </div>
            </div>

            <div className={styles.Button}>
              <Button
                fluid
                iconRight
                type="submit"
                isLoading={loginStatus === LoadingState.LOADING} onClick={handleLogin}
                disabled={disabledSubmit}>Войти</Button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}
