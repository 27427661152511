import React, {useCallback, useEffect, useRef} from 'react';
import styles from './Notification.module.scss';
import { ReactComponent as CLoseSVG } from '../../close.svg';


export interface NotificationProps {
  text: string;
  onClose: () => void;
}

export const Notification: React.FC<NotificationProps> = (props) => {
  const {
    text,
    onClose,
  } = props;

  const timeout = useRef<NodeJS.Timeout>();

  const handleClose = useCallback(() => {
    onClose();
    clearTimeout(timeout.current);
  }, [onClose]);

  useEffect(() => {
    timeout.current = setTimeout(handleClose, 5000);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [handleClose]);

  return (
    <div className={styles.Notification}>
      <div>{text}</div>
      <CLoseSVG className={styles.Action} onClick={onClose} />
    </div>
  );
};
