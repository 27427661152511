import {
  ContentTypes,
  DefaultError,
  ResponseApiService,
  SendApiServiceError,
  SendApiServiceOptions
} from "../models/apiFitch.model";

export enum LoadingState {
  NOT_ASKED = 'NOT_ASKED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export type QueryParamsType = Record<string | number, any>;

export const encodeQueryParam = (key: string, value: any) => {
  const encodedKey = encodeURIComponent(key);
  return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
}

export const addQueryParam = (query: QueryParamsType, key: string) => {
  return encodeQueryParam(key, query[key]);
}

export const addArrayQueryParam = (query: QueryParamsType, key: string) => {
  const value = query[key];
  return value.map((v: any) => encodeQueryParam(key, v)).join("&");
}

export const toQueryString = (rawQuery?: QueryParamsType): string => {
  const query = rawQuery || {};
  const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
  return keys
    .map((key) =>
      Array.isArray(query[key]) ? addArrayQueryParam(query, key) : addQueryParam(query, key),
    )
    .join("&");
}

export const addQueryParams = (rawQuery?: QueryParamsType): string => {
  const queryString = toQueryString(rawQuery);
  return queryString ? `?${queryString}` : "";
}

const HEADERS = {
  Accept: 'application/json',
};

export const CONTENT_TYPES = ContentTypes;

export function baseFetch(
  url: string,
  options: SendApiServiceOptions = {},
) {
  const {
    body,
    method = 'GET',
    contentType = CONTENT_TYPES.JSON,
    headers: optionHeaders = {},
    query,
    ...restOptions
  } = options;

  const stringifyBody = contentType === CONTENT_TYPES.JSON ? JSON.stringify(body) : body;

  const headers = {
    ...HEADERS,
    ...contentType === CONTENT_TYPES.JSON ? { 'Content-Type': contentType } : {},
    ...optionHeaders,
  };

  /**
   * Токен авторизации для всех запросов кроме SMS и LOGIN
   */
  // headers['Authorization'] = '';

  const queryString = query && toQueryString(query);

  return fetch(`${url}${queryString ? `?${queryString}` : ""}`, { body: stringifyBody, method, headers, ...restOptions })
    .then(async (response) => {
      if (response === undefined) {
        return null;
      }

      /**
       * Хэдер Content-Type
       */
      const contentType = response.headers.get('Content-Type');

      /**
       * Тип ответа на основе contentType
       */
      const mimeType = contentType ? contentType.split(';')[0].trim() : '';

      /**
       * Парсинг ответа на основе mimeType
       * json(): Promise<any> или text(): Promise<string>
       */
      const responseAnswer: string | JSON = ((mimeType === 'text/html') || (mimeType === '')) ? await response.text() : await response.json();

      /**
       * Парсинг ответа ошибки
       * Если прилетел text/html то вернет DefaultError
       * Иначе то что есть
       */
      const responseError: DefaultError | JSON = (typeof responseAnswer === 'string')
        ? { code: response.status, message: response.statusText }
        : responseAnswer as SendApiServiceError;

      return response.ok ? { data: responseAnswer, headers: response.headers } as ResponseApiService : Promise.reject(responseError);
    })
}
