import {QueryParamsType} from "../utils";

export declare type methodsApiType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface ResponseApiService<T = any> {
  data: T;
  headers: Headers;
}

export enum ContentTypes {
  JSON = 'application/json',
  FORM_DATA = 'multipart/form-data',
  BLOB = 'blob',
}

export interface SendApiServiceError {
  code?: number;
  description?: string;
  http_status_code?: number;
  status?: string;
  type?: string;
}

export type DefaultError = {
  code?: number;
  message?: string;
  description?: string;
  error_text?: string;
}

export enum UserRoleValue {
  Teacher = 'teacher',
  Principal = 'principal',
  Staff = 'staff',
  Deputy = 'deputy',
  SchoolAdmin = 'school_admin',
  Educator = 'educator',
  SchoolAdminReadOnly = 'school_admin_read_only',
  Methodologist = 'methodologist',
  PassportPrinter = 'passport_printer',
  Psychologist = 'psychologist',
  Administration = 'administration',
  NewsMaker = 'newsmaker',
  AeEducator = 'ae_educator',
  JuniorEducator = 'junior_educator',
  ResponsibleExtracurricularActivities = 'responsible_extracurricular_activities',
  UrsAdmin = 'urs_admin',
  UrsSupport = 'urs_support',
  UrsAuthor = 'urs_author',
  UrsManageAccess = 'urs_manage_access',
  UrsInfrastructureAdmin = 'urs_infrastructure_admin',

  ClassLead = 'class_lead',
  Curator = 'curator',
}

export enum ProfileType {
  Teacher = 'teacher',
  Curator = 'curator',
  Student = 'student',
  Parent = 'parent',
}

export interface UserProfiles {
  user_id: number,
  id: number,
  type: ProfileType,
  school_shortname: string,
  school_id: number,
  roles: UserRoleValue[],
  agree_pers_data: boolean,
  subject_ids: number[],
}

export interface AuthResponse {
  authentication_token: string;
  clusterId: number;
  date_of_birth: string;
  first_name: string;
  id: number;
  last_name: string;
  middle_name: string;
  password_change_required: boolean;
  phone_number: string;
  profiles: UserProfiles[];
  regional_auth: string;
  snils: string;
  sso_id: string;
}

/**
 * Модель параметров options
 */
export interface SendApiServiceOptions {
  body?: any;
  method?: methodsApiType;
  contentType?: ContentTypes;
  query?: QueryParamsType;

  headers?: HeadersInit;
  cache?: RequestCache;
  credentials?: RequestCredentials;
  integrity?: string;
  keepalive?: boolean;
  mode?: RequestMode;
  redirect?: RequestRedirect;
  referrer?: string;
  referrerPolicy?: ReferrerPolicy;
  signal?: AbortSignal | null;
  window?: any;
  publicPath?: string;
}
