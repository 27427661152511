export interface IConfig {
  service: string;
  kaluga: string;
  tatar: string;
  mo: string;
  region05: string;
  region72: string;
  region105: string;
  acl_subsystem: string;
  acl_url: string;
}

export const getConfig = (): IConfig => {
  const aupd_local_config = localStorage.getItem('aupd_local_config');
  return aupd_local_config?.length ? JSON.parse(aupd_local_config as string) : {
    service: '',
    kaluga: '',
    tatar: '',
    mo: '',
    region05: '',
    region72: '',
    region105: '',
  };
}
