import React, { useMemo } from 'react';
import './App.scss';
import packageInfo from '../package.json';
import {Auth} from "./components/auth/Auth";
import {Screen} from "./components/Screen";
import {BrowserRouter as Router} from "react-router-dom";
import GlobalFooter from "@mes-ui/global-footer";

function App() {

  const SOURCE = localStorage.getItem('aupd_source') || null;

  /**
   * Код региона для ФОС на основе aupd_source
   */
  const FOS: string = useMemo((): string => {
    return SOURCE ? `aupd_${SOURCE}` : 'aupd';
  }, [SOURCE]);

  return (
    <Router>
      <div className="App">
        <Screen>
          <Auth />
          <GlobalFooter
            auth
            version={packageInfo?.version}
            region={SOURCE || ''}
            subsystemName={FOS} />
        </Screen>
      </div>
    </Router>
  );
}

export default App;
