import React from 'react';
import styles from './ContentLoader.module.scss';

export interface ContentLoaderProps {
  className?: string;
  strokeWidth?: string | number;
}

export const ContentLoader: React.FC<ContentLoaderProps> = (props) => {
  const {
    strokeWidth,
    className,
  } = props;

  return (
    <div className={`${styles.ContentLoader} ${className}`}>
      <div className={styles.Area}>
        <div className={styles.Wrapper}>
          <svg className={styles.Spinner} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle
              className={styles.CirclePath}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              cx="33"
              cy="33"
              fill="none"
              r="30"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

ContentLoader.defaultProps = {
  strokeWidth: 6,
};
