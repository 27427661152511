import React, {useEffect, useMemo} from 'react';
import {useLocation} from "react-router";

interface Props {
  children: React.ReactNode;
}

export const Screen: React.FC<Props> = (props) => {
  const { children } = props;

  const { search } = useLocation();

  const postLogoutRedirectUri = useMemo((): string | null => {
    const params = new URLSearchParams(search);
    const urlRedirect =  params.get('post_logout_redirect_uri');

    if (urlRedirect?.includes('https') || urlRedirect?.includes('http')) {
      return urlRedirect;
    } else {
      return null;
    }
  }, [search]);

  useEffect(() => {
    if (postLogoutRedirectUri) {
      document.location.href = postLogoutRedirectUri;
    }
  }, [postLogoutRedirectUri])

  if (postLogoutRedirectUri) {
    return null;
  }

  return (
    <>
      { children }
    </>
  );
}
